@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Exo', 'Rubik', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Exo', 'Rubik', sans-serif;
}

.alice-carousel__stage-item {
    width: auto !important;
    margin-right: 1rem !important;
}

select:focus {
    outline: none !important;
    box-shadow: 0 0 2pt 1pt #009f6f;
    border-color: #009f6f;
    outline: 0;
}

select {
    padding: 5px;
    text-align-last: center;
    -webkit-appearance: none;
    background: url(./common/components/images/selectArrow.png) no-repeat right #009f6f;
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
}

.react-datepicker__month-select {
    padding: 5px;
    text-align-last: center;
    -webkit-appearance: none;
    background: white;
}

.react-datepicker__year-select {
    padding: 5px;
    text-align-last: center;
    -webkit-appearance: none;
    background: white;
}

input[type='radio'] {
    border: 0px;
    width: 1em;
    height: 1em;
}

input[type='radio']:after {
    width: 1em;
    height: 1em;
    border-radius: 15px;

    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 1em;
    height: 1em;
    border-radius: 15px;

    position: relative;
    background-color: #314164;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio'] + label span {
    transition: background 0.2s, transform 0.2s;
}

input[type='radio'] + label span:hover,
input[type='radio'] + label:hover span {
    transform: scale(1.2);
}

input[type='radio']:checked + label span {
    background-color: #314164;
    box-shadow: 0px 0px 0px 2px white inset;
}

input[type='radio']:checked + label {
    color: #314164;
}

.reserved-game {
    clip-path: polygon(67% 0, 100% 69%, 100% 1%);
}

.sport-zoom {
    transition: 1.5s ease;
    -moz-transition: 1.5s ease; /* Firefox */
    -webkit-transition: 1.5s ease; /* Chrome - Safari */
    -o-transition: 1.5s ease; /* Opera */
}

@media only screen and (min-width: 768px) {
    /* For desktop: */
    .sport-zoom:hover {
        transform: scale(1.05);
        -moz-transform: scale(1.05); /* Firefox */
        -webkit-transform: scale(1.05); /* Chrome - Safari */
        -o-transform: scale(1.05); /* Opera */
        -ms-transform: scale(1.05); /* IE9 */
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.alice-carousel__dots {
    margin: 0px 0px 5px !important;
}

/* ESTILO PARA EL HEXAGONO**/
.hex {
    position: relative;
    height: 45px;
    min-width: 100px;
    padding: 4px;
    margin: 15px;
    font-weight: bold;
    text-align: center;
    background: white;
    clip-path: polygon(25px 0px, calc(100% - 25px) 0px, 100% 50%, calc(100% - 25px) 100%, 25px 100%, 0px 50%);
}
.hex.gradient-border {
    color: white;
}
.hex:before {
    position: absolute;
    content: '';
    height: calc(120% - 11px); /* 100% - 2 * border width */
    width: calc(101% - 11px); /* 100% - 2 * border width */
    left: 3px; /* border width */
    top: 1px; /* border width */
    clip-path: polygon(22px 0px, calc(100% - 22px) 0px, 100% 50%, calc(100% - 22px) 100%, 22px 100%, 0px 50%);
    z-index: -1;
}

.hex.gradient-border:before {
    background-image: linear-gradient(
        to right,
        #93607b,
        #8b617e,
        #826280,
        #796281,
        #716381,
        #6a6483,
        #636584,
        #5c6684,
        #536986,
        #496b86,
        #406e85,
        #387082
    );
}
.hexa-text {
    margin-top: 20px;
    padding: 8px;
    transform: translateY(-50%);
}

.container-list {
    text-align: -webkit-center;
}

@media only screen and (max-width: 600px) {
    .hex {
        position: relative;
        height: 50px;
        min-width: 100px;
        padding: 6px;
        margin: 30px;
        /* border:white solid;*/
        font-weight: bold;
        text-align: center;
        background: white;
        /*background-image: linear-gradient(to right, #93607b, #8b617e, #826280, #796281, #716381, #6a6483, #636584, #5c6684, #536986, #496b86, #406e85, #387082);*/
        clip-path: polygon(25px 0px, calc(100% - 25px) 0px, 100% 50%, calc(100% - 25px) 100%, 25px 100%, 0px 50%);
    }
    .hex:before {
        position: absolute;
        content: '';
        height: calc(120% - 12px); /* 100% - 2 * border width */
        width: calc(102% - 12px); /* 100% - 2 * border width */
        left: 2px; /* border width */
        top: 1px; /* border width */
        clip-path: polygon(22px 0px, calc(100% - 22px) 0px, 100% 50%, calc(100% - 22px) 100%, 22px 100%, 0px 50%);
        z-index: -1;
    }
}

.css-1fdsijx-ValueContainer {
    height: 3.5rem !important;
    overflow: auto !important;
}
.css-3w2yfm-ValueContainer {
    overflow: auto !important;
}

.css-3w2yfm-ValueContainer {
    height: 3.5rem !important;
    overflow: auto !important;
}

.css-qbdosj-Input {
    text-align: left;
}
/* .multi-select-commune {
    text-align: -webkit-right;
} */
